import * as React from 'react';
import useContentfulAssets from '../../hooks/useContentfulAssets';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import * as Styled from './dedicatedHSEStyles';
import { SECTIONS } from '../../constants';
import isExternalUrl, { addTrailingSlash, isExternalUrlHref } from '../../utils';

const DedicatedHSE = ({ dedicatedHSEData }) => {
  const foundationLogoData = dedicatedHSEData?.filter((homeContainers) => {
    return (
      homeContainers?.type === SECTIONS.HOMEPAGE.DEDICATED_HSE.BACKGROUND_IMAGE
    );
  })[0];
  const safetyEnv = dedicatedHSEData?.filter((homeContainers) => {
    return (
      homeContainers?.type === SECTIONS.HOMEPAGE.DEDICATED_HSE.HOME_CONTAINER
    );
  })[0];
  const backgroundImage = foundationLogoData?.backgroundImages[0]?.file?.url;
  const ultramarFoundationLogo =
    foundationLogoData?.textWithLink1?.image?.file?.url;
  const ultramarFoundationTitle =
    foundationLogoData?.textWithLink1?.image?.title;
  const title = foundationLogoData?.textWithLink2?.header;
  const optionsMainStyle = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Styled.DedicatedHSEParagraph>{children}</Styled.DedicatedHSEParagraph>
      ),
      [INLINES.HYPERLINK]: (node, children) => (
        <Styled.DedicatedHSEButtonStyle
          href={
            isExternalUrlHref(node?.data?.uri)
              ? node?.data?.uri
              :addTrailingSlash(
                 process.env.GATSBY_DOMAIN_URL + node?.data?.uri
              )
          }
          target={isExternalUrl(node?.data?.uri) ? '_blank' : '_self'}
        >
          {children}
        </Styled.DedicatedHSEButtonStyle>
      ),
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const asset = useContentfulAssets(node?.data?.target?.sys?.id);
        return asset?.node?.file?.url?.includes('image') ? (
          <Styled.DedicatedHSEImages
            data-aos="fade-up"
            data-aos-delay="200"
            className="aos-init aos-animate"
            src={asset?.node?.file?.url}
            alt={asset?.node?.title}
          />
        ) : (
          <></>
        );
      },
    },
    renderText: (text) =>
      text.split('\n').flatMap((text, i) => [i > 0 && <br key={i} />, text]),
  };
  return (
    <React.Fragment>
      <Styled.ClearFix>
        <Styled.HomeSplit>
          <Styled.HomeSplitFoundation $imageUrl={backgroundImage}>
            <div>
              <Styled.HomeSplitFoundationImage
                src={ultramarFoundationLogo}
                alt={ultramarFoundationTitle}
              />
              <Styled.HomeSplitTitle>{title}</Styled.HomeSplitTitle>
              {foundationLogoData?.textWithLink2?.contentDetails?.raw &&
                documentToReactComponents(
                  JSON.parse(
                    foundationLogoData?.textWithLink2?.contentDetails?.raw
                  ),
                  optionsMainStyle
                )}
              {foundationLogoData?.textWithLink3?.contentDetails?.raw &&
                documentToReactComponents(
                  JSON.parse(
                    foundationLogoData?.textWithLink3?.contentDetails?.raw
                  ),
                  optionsMainStyle
                )}
            </div>
          </Styled.HomeSplitFoundation>
          <Styled.HomeSplitHSE>
            <div>
              {safetyEnv?.textWithLink1?.listOfImagesWithPath?.map(
                (item, index) => (
                  <Styled.DedicatedHSEImages
                    key={index}
                    data-aos="fade-up"
                    data-aos-delay={index * 200}
                    data-aos-once="true"
                    data-aos-duration="1000"
                    src={item?.image?.file?.url}
                    alt={item?.image?.title}
                  />
                )
              )}

              <Styled.HomeSplitTitle>
                {safetyEnv?.textWithLink2?.header}
              </Styled.HomeSplitTitle>
              {safetyEnv?.textWithLink2 &&
                documentToReactComponents(
                  JSON.parse(safetyEnv?.textWithLink2?.contentDetails?.raw),
                  optionsMainStyle
                )}
            </div>
          </Styled.HomeSplitHSE>
        </Styled.HomeSplit>
      </Styled.ClearFix>
    </React.Fragment>
  );
};

export default DedicatedHSE;
